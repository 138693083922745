import React, { useState, useRef, useContext, memo } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {useDrag, useDrop} from 'react-dnd';

import firebase from '../../../services/firebase';
import HomeContext from '../../../pages/Home/context';

import './style.css';




function PostCard(props) {

    const ref = useRef();
    const [showColors, setShowColors] = useState(Boolean);
    const [editando, setEditando] = useState(Boolean);
    const [remove, setRemove] = useState(String);
    const { docId, index } = props;
    const [titulo, setTitulo] = useState(String)

    const { move, posts } = useContext(HomeContext);
    
    const [{isDragging}, dragRef] = useDrag({
        item: {
            type: 'CARD',
            index: index
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    const [, dropRef] = useDrop({
        accept: 'CARD',
        hover(item, monitor){
            const draggedIndex = item.index;
            const targetIndex = index;
            if(draggedIndex === targetIndex){ return;}
            move(draggedIndex, targetIndex);            
            item.index = targetIndex;
        },
        drop: async (item, monitor) => {
            posts.map(async (post, index) => {
                await firebase.firestore().collection('posts').doc(post.id).update({ordem: (posts.length - index) + 1})
            })
        }
    });
    
    dragRef(dropRef(ref))


    function selecionaCor(typeSelect) {
        firebase.firestore().collection('posts').doc(docId).update({ type: typeSelect });
        setShowColors(!showColors);
    }

    function updateDone(done) {
        firebase.firestore().collection('posts').doc(docId).update({ done });
    }

    function removerPost() {
        firebase.firestore().collection('posts').doc(docId).delete();
    }

    function alterarTitulo(e) {

        e.preventDefault();
        if (titulo) {
            firebase.firestore().collection('posts').doc(docId).update({ post: titulo }).then(() => setEditando(false));
        }
        setEditando(false);
    }


    return (
        <div className={`card postCard ${ isDragging ? 'dragging' : null } ${props.type || 'default'}`} ref={ref}>
            <span className="headerCard">
                {
                    editando ?
                        <>
                            <textarea className="has-text-weight-medium is-size-5 txtEditando" rows="auto" onChange={(e) => setTitulo(e.target.value)} defaultValue={props.title} onKeyPress={(e) => e.which === 13 ? alterarTitulo(e) : null}></textarea>
                            <small className="obs">Tecla Enter para salvar</small>
                        </>

                        :
                        <>
                            <p className="has-text-weight-medium is-size-5 titleCard" onClick={() => setEditando(!editando)}>{props.title}</p>
                            <p className="has-text-weight-medium is-size-7 dataCard">{format(props.createdAt.toDate(), "dd 'de' MMM' de 'Y - hh:mm", { locale: ptBR })}</p>
                        </>
                }
                

            </span>

            <span className="espacoOptions">
                <hr className="is-visible" />
                {
                    showColors ?
                        <div className="selectColors">
                            <span onClick={() => setShowColors(!showColors)}><i className='bx bx-x'></i></span>
                            <div className="animated bounceIn success" onClick={() => selecionaCor('success')}></div>
                            <div className="animated bounceIn warning" onClick={() => selecionaCor('warning')}></div>
                            <div className="animated bounceIn danger" onClick={() => selecionaCor('danger')}></div>
                            <div className="animated bounceIn info" onClick={() => selecionaCor('info')}></div>
                            <div className="animated bounceIn pink" onClick={() => selecionaCor('pink')}></div>
                            <div className="animated bounceIn purple" onClick={() => selecionaCor('purple')}></div>
                            <div className="animated bounceIn default" onClick={() => selecionaCor('default')}></div>
                            
                        </div>
                        :
                        !remove ?
                            <div className="optionsCard">
                                <div onClick={() => setShowColors(!showColors)}><i className='bx bxs-color-fill'></i></div>
                                <div onClick={() => setRemove(!remove)}><i className='bx bx-trash'></i></div>
                                {
                                    !props.done ?
                                        <div onClick={() => updateDone(!props.done)}>
                                            <i className='bx bx-check'></i>
                                        </div>
                                        :
                                        <div className="emotion" onClick={() => updateDone(!props.done)}>
                                            <span role="img" aria-label="done">&#128526;</span>
                                        </div>
                                }
                            </div>
                            :
                            <div className="questionRemove">
                                <div className="button" onClick={() => setRemove(!remove)}>Cancelar</div>
                                <div className="button danger " onClick={removerPost}><i className='bx bx-trash'></i> Remover</div>
                            </div>
                }
            </span>
        </div>
    )
}

export default memo(PostCard);