import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Login/style.css';

import firebase from '../../services/firebase';

function Cadastro() {

    const [dados, setDados] = useState({ name: '', email: '', password: '' });
    const [loading, setLoading] = useState(Boolean);
    const [msg, setMsg] = useState({ tipo: '', message: '' });

    function cadastrar(e) {

        e.preventDefault();

        if (!dados.name || !dados.email || !dados.password) {
            setMsg({ tipo: 'warning', message: 'Todos os campos são obrigatórios' });
            return;
        }

        setLoading(true);
        firebase.auth().createUserWithEmailAndPassword(dados.email, dados.password).then((result) => {
            setLoading(false);
            setDados({ name: '', email: '', password: '' });
            firebase.firestore().collection('users').doc(result.user.uid).set({ name: dados.name, email: dados.email, avatar: '', createdAt: new Date() });
        }).catch((e) => {
            setLoading(false);
            setMsg({ tipo: 'danger', message: e.message });
        })
    }

    return (
        <div className="paginaLogin">
            <span className="animated pulse">
                <img src={require('../../assets/images/logo.png')} alt="Recadin" className="logoLogin" />
            </span>

            <div className="card cardLogin animated fadeIn">

                <div className="card-content">

                    <h3 className="title is-4">Crie uma Conta</h3>
                    <hr className="is-visible" />

                    {!!msg && msg.message ? <div className={`animated fadeIn message is-${msg.tipo}`}><div className="message-body">{msg.message}</div></div> : null}

                    <form onSubmit={cadastrar}>
                        <div className="control">
                            <label className="label">Nome</label>
                            <input type="text" className="input" value={dados.name} name="name" id="name" placeholder="Digite o seu nome." onChange={(e) => setDados({ ...dados, name: e.target.value })} />
                        </div>

                        <div className="control">
                            <label className="label">Email</label>
                            <input type="text" className="input" value={dados.email} name="email" id="email" placeholder="Digite o seu e-mail" onChange={(e) => setDados({ ...dados, email: e.target.value })} />
                        </div>

                        <div className="control">
                            <label className="label">Senha</label>
                            <input type="password" className="input" value={dados.password} name="password" id="password" placeholder="Digite a sua senha" onChange={(e) => setDados({ ...dados, password: e.target.value })} />
                        </div>

                        <button type="submit" className={`level-right button is-success level-left ${loading ? 'is-loading' : null}`} onClick={cadastrar} > {loading ? 'Carregando...' : 'Criar Conta'}
                        </button>
                        <Link to="../"><div className="button is-text level-left">Voltar</div></Link>
                    </form>

                </div>
            </div>

            <a href="https://za9.com.br" className="assinaturaZa9">
                <small>Um produto:</small>
                <img src={require('../../assets/images/logo_za9.png')} alt="Za9 Comunicação Digital" />
            </a>


        </div>
    );
}

export default Cadastro;
