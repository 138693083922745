import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


//Páginas
import Login from './pages/Login';
import LembrarSenha from './pages/LembrarSenha';
import Cadastro from './pages/Cadastro';
import Home from './pages/Home';
import Dica from './pages/Dica';
import PoliticaPrivacidade from './pages/PoliticaPrivacidade';
import TermosUso from './pages/TermosUso';


export const Private = () => (
    <Router>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/dica' component={Dica} />
            <Route path='/*' component={Home} />
        </Switch>
    </Router>
)

export const Public = () => (
    <Router>
        <Switch>
            <Route exact path='/' component={Login} />
            <Route path='/criar-conta' component={Cadastro} />
            <Route path='/lembrar-senha' component={LembrarSenha} />
            <Route path='/politica-privacidade' component={PoliticaPrivacidade} />
            <Route path='/termos-uso' component={TermosUso} />
            <Route path='/*' component={Login} />
        </Switch>
    </Router>
)

