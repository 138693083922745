import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import firebase from '../../services/firebase';
import Navbar from '../../components/navbar';
import './style.css'

function Dica() {

    const [dica, setDica] = useState(String);
    const [loading, setLoading] = useState(Boolean);
    const [success, setSuccess] = useState(Boolean);
    const [error, setError] = useState(Boolean);
    const [msgError, setMsgError] = useState(String);
    
    function sendFeedback(e) {
        e.preventDefault();

        if (!dica) {
            setError(true)
            return;
        }

        let user = firebase.auth().currentUser.uid;
        let newDica = {
            createdAt: new Date(),
            dica,
            user
        }

        setLoading(true);
        firebase.firestore().collection('feedbacks').add(newDica).then(() => {
            setLoading(false);
            setDica('');
            setSuccess(true);
        }).catch((e) => {
            setLoading(false);
            setMsgError(e.message);
        });

    }

    return (
        <>
        <Navbar />
        <div className="pagina paginaDica">

            <div className="card cardDica animated fadeIn">

        {
            success ?
                <div className="card-content animated fadeIn">
                    <h3 className="title is-4 tituloDica">Muito Obrigado! <i className='bx bx-heart'></i></h3>
                    <hr className="is-visible" />
                    <p>Seu feedback foi enviado com sucesso! Iremos avaliar e te daremos um retorno em breve.</p>
                    <Link to="../"><div className="button is-success">Voltar</div></Link>
                </div>
            :
            <div className="card-content animated fadeIn">
                <h3 className="title is-4 tituloDica">Sentiu falta de alguma funcionalidade? <i className='bx bx-rocket'></i></h3>
                <hr className="is-visible" />
                <p>O <b>Recadin</b> acaba de nascer e ainda estamos em versão beta(testes) e ficaremos super felizes em receber um feedback seu, dizendo o que podemos melhorar.</p>
                {!!msgError ? <div className={`animated fadeIn message is-danger`}><div className="message-body">{msgError}</div></div> : null}

                <form onSubmit={sendFeedback}>
                    <div className="control">
                        <textarea className={`input campoDica ${error ? 'inputWarning' : null}`} defaultValue={dica} name="dica" id="dica" placeholder="Digite sua sugestão de funcionalidade aqui." onChange={(e) => setDica(e.target.value)} ></textarea>
                    </div>
                    <button type="submit" className={`button is-success ${loading ? 'is-loading' : null} level-left`} onClick={sendFeedback}>{loading ? 'Carregando...' : 'Enviar'}</button>
                    <Link to="../"><div className="button is-text level-left">Voltar</div></Link>
                </form>
            </div>
        }

               

            </div>

            <a href="https://za9.com.br" className="assinaturaZa9">
                <small>Um produto:</small>
                <img src={require('../../assets/images/logo_za9.png')} alt="Za9 Comunicação Digital" />
            </a>


        </div>
        </>
    );
}

export default Dica;
