import React, { useState, useEffect } from 'react';
import firebase from '../../services/firebase';
import './style.css';
import { Link } from 'react-router-dom';

export default function Navbar() {

    const [user, setUser] = useState({});

    useEffect(() => {
        const userUid = firebase.auth().currentUser.uid;
        return firebase.firestore().collection('users').doc(userUid).onSnapshot((snap) => {
            setUser(snap.data());
        })
    }, [])

    function logout() {
        firebase.auth().signOut();
    }

    function getFirstName(name){
      if(name){
        let nomes = name.split(" ")
        return `${nomes[0]}`;
      }
    }

    return (
        <div className="navbar">
            <Link to="/"><img src={require('../../assets/images/logo.png')} alt="Recadin" className="logo" /></Link>
            <div className="profile">
                <Link to="/dica"><span className="iconeMenu"><i className='bx bx-rocket'></i></span></Link>
                <span className="iconeMenu" onClick={logout}><i className='bx bx-exit'></i></span>
                <span className="profileName">{getFirstName(user.name || '')}</span>
                <div className="avatar is-small">
                    <img className="profileAvatar" src={user?.avatar || require('../../assets/images/placeholder_avatar.png')} alt="" />
                </div>
            </div>
        </div>
    );
}
