import React, { useState, useEffect } from 'react';
import './style.css';
import Navbar from '../../components/navbar';
import PostCard from '../../components/cards/postCard';
import AddCard from '../../components/cards/addCard';
import firebase from '../../services/firebase';

////////////////////////////////////
// Importações para DnD
////////////////////////////////////
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import HomeContext from './context';
import produce from 'immer';


function Home(id) {

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(Boolean);


    useEffect(() => {
        let user = firebase.auth().currentUser.uid;
        setIsLoading(true)
        const loadPosts = firebase.firestore().collection('posts').where('user', '==', user).orderBy('ordem', 'desc').onSnapshot(snap => {
            setIsLoading(false)
            setPosts(snap.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        }, err => {
            setIsLoading(false)
            console.log(err)
        })

        return () => loadPosts()

    }, [id]);


    ////////////////////////////////////
    // Importações para DnD
    ////////////////////////////////////
    function move(from, to){
        
        setPosts(produce(posts, draft => {
            const dragPost = draft[from];
            draft.splice(from, 1);
            draft.splice(to, 0, dragPost);
        }))

    }

    return (
        <>
            <Navbar />

            <div className="pagina">
                <AddCard listCount={posts.length} />
                {isLoading ? <div className="loader is-medium is-inverted"></div> : null}
               
                <DndProvider backend={HTML5Backend}>
                   <HomeContext.Provider value={{posts, move}}>
                        <div className="cards">
                            {posts.map((post, index) => (
                                <PostCard key={post.id} index={index} docId={post.id} title={post.post} createdAt={post.createdAt} type={post.type} done={post.done} />
                            ))}
                        </div>
                   </HomeContext.Provider>
                </DndProvider>

            </div>
        </>
    );
}

export default Home;