import React, { useState, useEffect } from 'react';
import { Private, Public } from './Routes';
import './assets/css/animate.min.css';
import './assets/css/principal.css';
import firebase from './services/firebase';
import 'biings-ds';

function App() {

  const [logado, setLogado] = useState(Boolean);
  const [loading, setLoading] = useState(Boolean);

  useEffect(() => {
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      setLoading(false);
      if (user) {
        setLogado(true);
      } else {
        setLogado(false);
      }
    })

  }, [logado]);

  return (
    loading ? <div className="loader is-medium is-inverted"></div> : logado ? <Private /> : <Public />
  );
}

export default App;
