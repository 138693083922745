import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import firebase from '../../services/firebase';

function Login() {

    const [dados, setDados] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(Boolean);
    const [msg, setMsg] = useState({ tipo: '', message: '' });

    function acessar(e) {
        e.preventDefault();
        if (!dados.email || !dados.password) {
            setMsg({ tipo: 'warning', message: 'Todos os campos são obrigatórios' });
            return;
        }
        setLoading(true);

        firebase.auth().signInWithEmailAndPassword(dados.email, dados.password).then((result) => {
            setLoading(false);
            setDados({ email: '', password: '' });
        }).catch((e) => {
            setLoading(false);
            setMsg({ tipo: 'danger', message: e.message });
        })
    }


    function loginFB() {
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            const user = result.user;
            firebase.firestore().collection('users').doc(result.user.uid).set({ name: user.displayName, avatar: user.photoURL, email: user.email, createdAt: new Date() });
        }).catch((e) => {
            setMsg({ tipo: 'danger', message: e.message });
        });
    }

    return (
        <div className="paginaLogin">
            <span className="animated pulse">
                <img src={require('../../assets/images/logo.png')} alt="Recadin" className="logoLogin" />
            </span>

            <div className="card cardLogin animated fadeIn">

                <div className="card-content">

                    <h3 className="title is-4">Login</h3>
                    <hr className="is-visible" />

                    {!!msg && msg.message ? <div className={`animated fadeIn message is-${msg.tipo}`}><div className="message-body">{msg.message}</div></div> : null}

                    <form onSubmit={acessar}>
                        <div className="control">
                            <label className="label">Email</label>
                            <input type="text" className="input" value={dados.email} name="email" id="email" placeholder="Digite o seu e-mail" onChange={(e) => setDados({ ...dados, email: e.target.value })} />
                        </div>

                        <div className="control">
                            <div className="label">
                                <label className="label">Senha</label>
                                <small><Link to="./lembrar-senha">Esqueci minha senha</Link></small>
                            </div>
                            <input type="password" className="input" value={dados.password} name="password" id="password" placeholder="Digite a sua senha" onChange={(e) => setDados({ ...dados, password: e.target.value })} />
                        </div>

                        <button type="submit" className={`button is-success ${loading ? 'is-loading' : null} level-left`} onClick={acessar} >{loading ? 'Carregando...' : 'Acessar'}</button>
                        <div className="button btnFb" onClick={() => loginFB()}><i className='bx bxl-facebook'></i> Facebook</div>
                        <Link to="./criar-conta"><div className="button is-text level-left">Criar uma conta</div></Link>
                    </form>

                </div>
            </div>

            <a href="https://za9.com.br" className="assinaturaZa9">
                <small>Um produto:</small>
                <img src={require('../../assets/images/logo_za9.png')} alt="Za9 Comunicação Digital" />
            </a>


        </div>
    );
}

export default Login;
