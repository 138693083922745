import React, { useState, useEffect } from 'react';
import './style.css';
import firebase from '../../../services/firebase';

function AddCard({listCount}) {

    const [post, setPost] = useState(String);
    const [error, setError] = useState(Boolean);
    const [loading, setLoading] = useState(Boolean);
    const [msgError, setMsgError] = useState(String);

    function addPost(e) {
        e.preventDefault();

        if (!post) {
            setError(true)
            return;
        }

        let user = firebase.auth().currentUser.uid;

        let newPost = {
            createdAt: new Date(),
            post,
            user,
            ordem: listCount + 1
        }

        setLoading(true);
        firebase.firestore().collection('posts').add(newPost).then(() => {
            setLoading(false);
            setPost('');
        }).catch((e) => {
            setLoading(false);
            setMsgError(e.message);
        });

    }

    useEffect(() => {
        if (post.length > 0) {
            setError(false);
        }
    }, [post])

    return (
        <div className="areaFormPost">
            {!!msgError ? <div className={`animated fadeIn message is-danger`}><div className="message-body">{msgError}</div></div> : null}
            <div className={`formAdd ${error ? 'inputWarning' : null}`}>
                <input id="fieldId" className="inputAdd" onChange={(e) => setPost(e.target.value)} placeholder="Digite aqui um novo lembrete." value={post} onKeyPress={(e) => e.key === 'Enter' ? addPost(e) : null} />
                <button onClick={addPost} className={`level-right button is-success level-left btnAdd ${loading ? 'is-loading' : null}`}><i className='bx bx-plus'></i></button>
            </div>
        </div>
    )
}

export default AddCard;