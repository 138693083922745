import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../services/firebase';

import '../Login/style.css';


function LembrarSenha() {

    const [dados, setDados] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(Boolean);
    const [msg, setMsg] = useState({ tipo: '', message: '' });

    function enviar(e) {
        e.preventDefault();
        if (!dados.email) {
            setMsg({ tipo: 'warning', message: 'Digite o seu e-mail.' });
            return;
        }
        setLoading(true);

        firebase.auth().sendPasswordResetEmail(dados.email).then((result) => {
            setLoading(false);
            setDados({ email: ''});
            setMsg({ tipo: 'success', message: 'Solicitação realizada com sucesso. Enviamos um link de confirmação para o seu e-mail.' });
        }).catch((e) => {
            setLoading(false);
            setMsg({ tipo: 'danger', message: e.message });
        })
    }

    return (
        <div className="paginaLogin">
            <span className="animated pulse">
                <img src={require('../../assets/images/logo.png')} alt="Recadin" className="logoLogin" />
            </span>

            <div className="card cardLogin animated fadeIn">

                <div className="card-content">

                    <h3 className="title is-4">Esqueci minha senha</h3>
                    <hr className="is-visible" />

                    {!!msg && msg.message ? <div className={`animated fadeIn message is-${msg.tipo}`}><div className="message-body">{msg.message}</div></div> : null}

                    <form onSubmit={enviar}>
                        <div className="control">
                            <label className="label">Digite o seu e-mail</label>
                            <input type="text" className="input" value={dados.email} name="email" id="email" placeholder="@" onChange={(e) => setDados({ ...dados, email: e.target.value })} />
                        </div>

                        <button type="submit" className={`button is-success ${loading ? 'is-loading' : null} level-left`} onClick={enviar} >{loading ? 'Carregando...' : 'Solicitar Senha'}</button>
                        <Link to="../"><div className="button is-text level-left">Voltar</div></Link>
                    </form>

                </div>
            </div>

            <a href="https://za9.com.br" className="assinaturaZa9">
                <small>Um produto:</small>
                <img src={require('../../assets/images/logo_za9.png')} alt="Za9 Comunicação Digital" />
            </a>


        </div>
    );
}

export default LembrarSenha;
