import firebase from 'firebase/app';
import 'firebase/auth'; 
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyBz9EnGqmIzEXGBafk_u8VmmKHfDkE8KGs",
  authDomain: "za9-recadin.firebaseapp.com",
  databaseURL: "https://za9-recadin.firebaseio.com",
  projectId: "za9-recadin",
  storageBucket: "za9-recadin.appspot.com",
  messagingSenderId: "545642943714",
  appId: "1:545642943714:web:e5af6c408c7c24b7887abb",
  measurementId: "G-HS954ZR7WT"
};

firebase.initializeApp(config);

export default firebase;

